const IconEconomic = () =>{
    return(
        <svg width="40px" height="40px" viewBox="0 0 40 40">
            <title>icon-economic</title>
            <g id="icon-economic" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M35.1920689,10 L35.1920689,19.7071068 L31.7110689,16.226 L23.063716,25.8344824 L22.7695066,26.1613817 L22.40774,25.9112871 C19.0676817,23.6022554 16.5372953,21.7207999 14.8130739,20.2632844 L14.7360689,20.198 L6.06371598,29.8344824 L5.71116633,30.2262042 L5.33851552,29.8535534 L3.33851552,27.8535534 L3,27.5150379 L3.32349184,27.1621377 L14.3234918,15.1621377 L14.6296289,14.82817 L14.9920689,15.1 L22.6200689,20.821 L29.0070689,13.522 L26.3385155,10.8535534 L25.4849621,10 L26.6920689,10 L35.1920689,10 Z M34.1920689,11 L27.8980689,11 L30.0456223,13.1464466 L30.3763903,13.4772146 L23.0683573,21.8292523 L22.7630178,22.1782117 L22.3920689,21.9 L14.7540689,16.171 L4.38306891,27.484 L5.67206891,28.773 L14.3204218,19.1655176 L14.6502588,18.7990321 L15.0213212,19.1237117 C16.7080927,20.5996367 19.3613025,22.5895441 22.9763978,25.0887129 L22.6150689,24.837 L31.3204218,15.1655176 L31.6729715,14.7737958 L34.1920689,17.293 L34.1920689,11 Z" id="Combined-Shape" fill="#405363" fillRule="nonzero"></path>
            </g>
        </svg>
    )
}

export default IconEconomic;