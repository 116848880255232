const IconInfrastructure = () =>{
    return(
        <svg width="40px" height="40px" viewBox="0 0 40 40">
            <title>icon-infrastructure</title>
            <g id="icon-infrastructure" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M36,11.5 L36,28.2142857 L21,37.5 L21,20.7857143 L36,11.5 Z M4,11.5 L19,20.7857143 L19,37.5 L4,28.2142857 L4,11.5 Z M35,13.295 L22,21.342 L22,35.704 L35,27.657 L35,13.295 Z M5,13.295 L5,27.657 L18,35.704 L18,21.342 L5,13.295 Z M20,2 L34.5,11 L20,20 L5.5,11 L20,2 Z M20,3.176 L7.396,11 L20,18.823 L32.603,11 L20,3.176 Z" id="Combined-Shape" fill="#405363" fillRule="nonzero"></path>
            </g>
        </svg>
    )
}

export default IconInfrastructure;