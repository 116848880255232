import React from 'react';
import styles from "./detail.module.scss";
import { formatNumberWithDot, imageLink } from '../../../hooks/utils';
import CountryFlag from "../countryFlag/country-flag";
import {getNumberWithOrdinalWithSuperscript} from "../../../utils/utils";
import SocialShare from "../social-share/social-share";

// const countryLinks = [{'country': 'Singapore', 'text': 'cryptocurrencies', 'link': '' },];
const countryLinks = [{'country': 'Singapore', 'text': '1st', 'digital': 1, 'suptext': 'st'},
                                                {'country': 'Hong Kong (SAR China)', 'text': '2nd', 'digital': 2, 'suptext': 'nd'},
                                                {'country': 'UAE', 'text': '3rd', 'digital': 3, 'suptext': 'rd'},
                                                {'country': 'USA', 'text': '4th', 'digital': 4, 'suptext': 'th'},
                                                {'country': 'UK', 'text': '5th', 'digital': 5, 'suptext': 'th'},
                                                {'country': 'Malta', 'text': '6th', 'digital': 6, 'suptext': 'th'},
                                                ];

const WealthFlowDetail = ({item, onClose}) =>{
    // const value = (item.inflowVal) ? item.inflowVal : item.outflowVal;
    const countryName = item.country;

    const value = item.total;
    // const signVal = value > 0 ? "+" + formatNumberWithDot(value) : "-" + formatNumberWithDot(Math.abs(value));
    const signVal = item.total;

    // const notes = item.notes ? item.notes : 'notes placement ';
    const urlText = `Apply for residence in ${countryName}`;
    const urlTextUK = `Apply for residence in the ${countryName}`;

    // const url = item.url ? (<p className="mt-auto mb-0">{urlText} <a href={item.url} target="_blank" rel="noreferrer" className="fw-bold text-underline">here</a></p>) : (<p className="mt-auto mb-0">&nbsp;</p>);
    const url = item.url ? (<p className="mt-auto mb-0">{urlText} <a href={item.url} target="_blank" rel="noreferrer" className="fw-bold text-underline">here</a>.</p>) : (null);

    // const circleMark = item.category === "inflow" ? (<span className="circle circle-inflow"></span>) : (<span className="circle circle-outflow"></span>);

    const targetLinksItem = countryLinks.filter((i) => i.country === item.country)[0];
    const splited =  (targetLinksItem) ? (item.notes).split(targetLinksItem.text) : null;

    const newNotesCountry = (targetLinksItem) ?
        (<p className="mt-auto">{splited[0]} <a href={targetLinksItem.link} target="_blank" rel="noreferrer" className="fw-bold text-underline">{targetLinksItem.text}</a> {splited[1]}</p>)
        : (<p>{item.notes}</p>);
    const newNotes = (targetLinksItem) ?
        (<p className="mt-auto">{splited[0]} <span>{targetLinksItem.digital}<sup>{targetLinksItem.suptext}</sup></span> {splited[1]}</p>)
        : (<p>{item.notes}</p>);


    return(
        <div
            className={`container shadow ${styles.wealthpopup} ${styles.popupWealthFlowCard} ${styles.card} p-0 bg-light`
            // style={{top: '-50px'}}
        }
        >
            <button
                className={`${styles.closeButton} ${styles.shadowBackground}`}
                onClick={onClose}
                style={{width: '32px', height: '32px'}}
            >
                {/*close*/}
                <svg width="10px" height="9px" viewBox="0 0 10 9" style={{overflow: 'visible'}}>
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-702.000000, -7.000000)" stroke="currentColor">
                            <g transform="translate(700.692388, 4.692388)">
                                <g transform="translate(6.500000, 6.500000) rotate(45.000000) translate(-6.500000, -6.500000) ">
                                    <line x1="6.5" y1="1" x2="6.5" y2="12"></line>
                                    <line x1="6.5" y1="1" x2="6.5" y2="11.5"
                                          transform="translate(6.500000, 6.250000) rotate(-90.000000) translate(-6.500000, -6.250000) "></line>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
            <div className={`col position-static ${styles.colPopup}`}>
                <div className={`row ${styles.rowImage}`}>

                    <div className={styles.countryImageWrapper}>
                        <img src={imageLink(item.name)} alt={countryName}/>
                    </div>
                </div>
                <div className={`row d-flex flex-column justify-content-between p-4 ${styles.rowContent}`}>
                    <div className='row '>
                        <div className={`col-md-8 col-sm-12`}>
                        {/*<div className={`col-8 ${styles.col8p5}`}>*/}
                            <span className={`text-uppercase ${styles.headerScore} text-right`}>The Henley Crypto Adoption Index 2024 <span
                                className={styles.outOf60}><br/></span></span>
                            <h3
                                className={styles.headerH2}>{item.country}</h3>
                        </div>

                        <div className={`d-none d-lg-block col-md-4 d-inline-block text-right ${styles.hiddenMobile}`}>
                            {/*<div className={`col-4 d-flex flex-column justify-content-between text-right ${styles.col3p5}`}>*/}
                            {/*<div className="col-4 d-flex flex-column justify-content-between">*/}
                            {/*<h3 className={` text-right text-nowrap align-bottom ${styles.hwni}`}>{signVal} HNWIs</h3>*/}
                            {/*</div>*/}
                            <span className={`text-uppercase ${styles.headerScore} ${styles.scoreField}`}>Total Score<span
                                className={styles.outOf60}>(Out of 60)</span></span>
                            <h3 className={styles.headerH2}>{item.total.toFixed(1)}</h3>
                        </div>
                    </div>
                    {newNotes}

                    <div className={`d-flex justify-content-between`}>
                        {(item.name === 'uk') ?
                            (<p className="mt-auto mb-0">{urlTextUK} <a href={item.url} target="_blank" rel="noreferrer"
                                                                        className="fw-bold text-underline">here</a>.
                            </p>)
                            : url}

                        <div className={``}>
                            <SocialShare shareUrl={item.url}/>
                        </div>
                    </div>

                </div>


            </div>

        </div>

    )
}

export default WealthFlowDetail;
