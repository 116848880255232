import React, {Fragment} from 'react';
import {useState} from "react";
import styles from './crypto-wealth.module.scss';
import {cryptoWealthData} from '../../data/data-crypto-wealth';
import SelectDropdown from '../common/selectDropdown/select-dropdown';
import IconBitcoin from "../common/icon/icon-bitcoin";
import IconCrypto from "../common/icon/icon-crypto";

const options = [
    { label: "Total Crypto", value: "totalCrypto" },
    { label: "Total Crypto Growth % (1 year)*", value: "totalCryptoGrowth" },
    { label: "Bitcoin", value: "bitcoin" },
    { label: "Bitcoin Growth % (1 year)*", value: "bitcoinGrowth" },
];

const CryptoWealth = () => {
    const [sortColumn, setSortColumn] = useState("totalCrypto");

    const handleOptionSelect = (option) => {
        // setSelectedOption(option);
        setSortColumn(option.value);
        // setDisplay(-1);
    };

    return (
        <>
            {/*<h1>Global Crypto Wealth Statistics</h1>*/}
            <div className={`d-md-none ${styles.sortMobileWrapper}`}>
                <div className={styles.sortTitle}>Sort by</div>
                <SelectDropdown options={options} onSelect={handleOptionSelect}/>
            </div>
            <table className={`chartTable ${styles.table} ${styles.cryptoWealthChartTable}`}>
                <thead>
                <tr>
                    <th className={`text-left ${styles.headerColumn} ${styles.cellHideMobile} ${styles.noBottomBorder}`}>
                        {/*WorldWide*/}
                    </th>

                    <th colSpan={2}
                        className={`text-center py-3 ${styles.dataColumn} ${styles.showMobile}  ${styles.colHeader} `}>
                        <IconCrypto className={`column-icon`}/> Crypto
                    </th>

                    <td className={`text-left ${styles.emptyColumn} ${styles.showMobile} ${styles.noBottomBorder} `}>&nbsp;</td>

                    <th colSpan={2}
                        className={`text-center py-3 ${styles.dataColumn} ${styles.showMobile}  ${styles.colHeader} `}>
                        <IconBitcoin className={`column-icon`} height={40} width={40}/> Bitcoin
                    </th>
                </tr>

                <tr>
                    <th className={`text-left ${styles.headerColumn} ${styles.cellHideMobile} ${styles.colHeader} ${sortColumn ? styles.selectedColumn : ''} `} style={{background: 'none'}}>
                        {/*WorldWide*/}
                    </th>
                    <th className={`text-right ${styles.dataColumn} ${styles.cellHideMobile} ${styles.colHeader} ${sortColumn === 'totalCrypto' ? styles.selectedColumn : ''} `}>
                        <IconCrypto className={`column-icon `}/>Total Crypto
                    </th>
                    <th className={`text-right ${styles.dataColumn} ${styles.cellHideMobile} ${styles.colHeader} ${sortColumn === 'totalCryptoGrowth' ? styles.selectedColumn : ''} `}>
                        <IconCrypto className={`column-icon `}/>Total Crypto <br/>Growth %<br/>
                        (1 year)<sup>*</sup>
                    </th>

                    <td className={`text-right ${styles.emptyColumn} ${styles.cellHideMobile} ${styles.colHeader} `}>&nbsp;</td>

                    <th className={`text-right ${styles.dataColumn} ${styles.cellHideMobile} ${styles.colHeader} ${sortColumn === 'bitcoin' ? styles.selectedColumn : ''} `}>
                        <IconBitcoin className={`column-icon`}/> Bitcoin
                    </th>
                    <th className={`text-right ${styles.dataColumn} ${styles.cellHideMobile} ${styles.colHeader} ${sortColumn === 'bitcoinGrowth' ? styles.selectedColumn : ''} `}>
                        <IconBitcoin className={`column-icon `}/> Bitcoin Growth %<br/>
                        (1 year)<sup>*</sup>
                    </th>

                </tr>

                <tr>
                    <th className={`text-left ${styles.headerColumn} ${styles.showMobile} `}>
                    {/*WorldWide*/}
                    </th>
                    <th className={`text-right align-middle ${styles.dataColumn} ${styles.showMobile} ${styles.colHeader} `}>
                        Total
                    </th>
                    <th className={`text-right align-middle ${styles.dataColumn} ${styles.showMobile} ${styles.colHeader} `}>
                        Growth % (1 year)<sup>*</sup>
                    </th>

                    <td className={`text-right ${styles.emptyColumn} ${styles.showMobile} `}>&nbsp;</td>

                    <th className={`text-right align-middle ${styles.dataColumn} ${styles.showMobile} ${styles.colHeader} `}>
                        Total
                    </th>
                    <th className={`text-right align-middle ${styles.dataColumn} ${styles.showMobile} ${styles.colHeader} `}>
                        Growth % (1 year)<sup>*</sup>
                    </th>
                </tr>
                </thead>
                <tbody>
                {cryptoWealthData.map((item, index) => (
                    <Fragment key={index}>
                        {item.isTotal === true && <tr className={`${styles.contentRow} ${styles.spacer}`}>
                            <td className={`text-left  ${styles.headerColumn}`}>&nbsp;</td>
                            <td className={`text-right ${styles.dataColumn} `}>&nbsp;</td>
                            <td className={`text-right ${styles.dataColumn} ${styles.cellHideMobile} `}>&nbsp;</td>
                            <td className={`text-right ${styles.dataColumn} ${styles.cellHideMobile}`}>&nbsp;</td>
                            <td className={`text-right ${styles.dataColumn} ${styles.cellHideMobile} `}>&nbsp;</td>
                            <td className={`text-right ${styles.dataColumn} ${styles.cellHideMobile} `}>&nbsp;</td>
                        </tr>}
                        <tr className={`${styles.contentRow} ${item.isTotal === true ? styles.totalRow : ''}`}>
                            <td className={`text-left  ${styles.headerColumn}`}>
                                {item.worldWide}
                            </td>

                            <td className={`text-right ${styles.dataColumn} 
                                                       ${item.boldTotalCrypto === true ? styles.totalCrypto : ''}
                                                       ${styles.cellHideMobile} ${styles.cellOnMobile} ${sortColumn === 'totalCrypto' ? styles.selectedColumn : ''} `}>
                                {item.totalCrypto.toLocaleString('en-AU')}
                            </td>
                            <td className={`text-right ${styles.dataColumn} 
                                                       ${item.boldTotalCrypto === true ? styles.totalCrypto : ''}
                                                       ${styles.cellHideMobile} ${styles.cellOnMobile} ${sortColumn === 'totalCryptoGrowth' ? styles.selectedColumn : ''}  `}>
                                {item.totalCryptoGrowth > 0 ? '+' : '' }{item.totalCryptoGrowth.toLocaleString('en-AU') + '%'}
                            </td>

                            <td className={`text-right ${styles.emptyColumn} ${styles.cellHideMobile}`}>&nbsp;</td>

                            <td className={`text-right ${styles.dataColumn}
                                                       ${styles.cellHideMobile} ${styles.cellOnMobile} ${sortColumn === 'bitcoin' ? styles.selectedColumn : ''} `}>
                                {item.bitcoin.toLocaleString('en-AU')}
                            </td>
                            <td className={`text-right ${styles.dataColumn} 
                                                       ${styles.cellHideMobile} ${styles.cellOnMobile} ${sortColumn === 'bitcoinGrowth' ? styles.selectedColumn : ''} `}>
                                {item.totalCryptoGrowth > 0 ? '+' : '' }{item.bitcoinGrowth.toLocaleString('en-AU') + '%'}
                            </td>
                        </tr>
                    </Fragment>
                ))}
                </tbody>
            </table>
            <div className={`${styles.footerNote}`}>
                <div className='row'>
                    <div className='pl-3'>
                        <p><strong>Notes:</strong></p>
                        <ul>
                            <li>Total crypto includes Bitcoin and all other known cryptocurrencies.</li>
                            <li>Millionaire figures rounded to nearest 100.</li>
                            <li>All statistics as at 30 June 2024.</li>
                            <li><sup>*</sup>One-year growth rate from 1 July 2023 to 30 June 2024.</li>
                        </ul>
                    </div>
                    <div className='pl-3'>
                        <p><strong>Crypto wealth tiers:</strong></p>
                        <ul>
                            <li>Millionaires — Individuals with crypto holdings of USD 1 million or more</li>
                            <li>Centi-millionaires — Individuals with crypto holdings of USD 100 million or more</li>
                            <li>Billionaires — Individuals with crypto holdings of USD 1 billion or more</li>
                        </ul>
                    </div>
                </div>
                <p><strong>Source: New World Wealth</strong></p>
            </div>
        </>
    )
}

export default CryptoWealth;