import {Fragment, useEffect, useRef, useState} from "react";
import styles from "./CryptoAdoptionIndex.module.scss";
import {useIsMobile900} from "../../hooks/mobile.js";
import {getNumberWithOrdinalWithSuperscript} from "../../utils/utils";
import IconAdoption from "../common/icon/icon-adoption";
import IconInfrastructure from "../common/icon/icon-infrastructure";
import IconInnovations from "../common/icon/icon-innovations";
import IconRegulatory from "../common/icon/icon-regulatory";
import IconEconomic from "../common/icon/icon-economic";
import IconTax from "../common/icon/icon-tax";
import {cryptoInvestmentMigrationData} from "../../data/data-crypto-investment-migration";
import WealthFlowDetail from "../common/popup/WealthFlowDetail";
import CountryInfoDetail from "../common/popup/CountryInfoDetail";

const iconPlus = (
    <svg
        className={styles.plus}
        aria-hidden="true"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 384 512">
        <path fill="currentColor"
              d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path>
    </svg>)

const iconMinus = (
    <svg
        className={styles.minus}
        aria-hidden="true"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 384 512">
        <path fill="currentColor"
              d="M368 224H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h352c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path>
    </svg>)

const iconTick = (
    <svg
        className={styles.check}
        aria-hidden="true"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path fill="#fff"
              d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"></path>
    </svg>)

const SCORE_COLUMNS = [
    {
        key: "publicAdoption",
        name: "Public adoption",
        color: "#405363",
        icon: IconAdoption
    },
    {
        key: "infrastructureAdoption",
        name: "Infrastructure adoption",
        color: "#4B4B4D",
        icon: IconInfrastructure
    },
    {
        key: "innovationAndTechnology",
        name: "Innovation and technology",
        color: "#6F7072",
        icon: IconInnovations
    },
    {
        key: "regulatoryEnvironment",
        name: "Regulatory environment",
        color: "#C1C5C8",
        icon: IconRegulatory
    },
    {
        key: "economicFactors",
        name: "Economic factors",
        color: "#DFE1E2",
        icon: IconEconomic
    },
    {
        key: "taxFriendliness",
        name: "Tax-friendliness",
        color: "#CFD9E1",
        icon: IconTax
    }
]

export default function CryptoAdoptionIndex() {
    const [data, setData] = useState(null);
    const [sortedData, setSortedData] = useState(null);
    const [selectedParameters, setSelectedParameters] = useState([]);
    const [disabledParameters, setDisabledParameters] = useState(false);
    const [collapsedParameters, setCollapsedParameters] = useState(true);
    const [expandedCountries, setExpandedCountries] = useState([]);
    const [hoveredParameter, setHoveredParameter] = useState(null);

    const [showCard, setShowCard] = useState(false);
    const [detailCard, setDetailCard] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [marginTopRow, setMarginTopRow] = useState(0);
    const [marginBottomRow, setMarginBottomRow] = useState(0);
    const numOfCountries = cryptoInvestmentMigrationData.length;

    const scrollRef = useRef(null)
    const selectRef = useRef(null)

    const isMobile = useIsMobile900();

    useEffect(() => {
        const result = cryptoInvestmentMigrationData.map((d) => {
            return {
                total: d.total,
                scores: SCORE_COLUMNS.map((e) => {
                    return {
                        score: e.key,
                        name: e.name,
                        icon: e.icon,
                        value: d[e.key],
                        color: e.color,
                    }
                }),
                rank: d.rank,
                country: d.country,
                name: d.name,
                notes: d.notes,
                url: d.url,
                innerHeight: document.getElementById('country_' + d.name) ? document.getElementById('country_' + d.name).offsetHeight : 79,
            }
        })

        setData(result);
        setSortedData(result);

        const handleResize = () => {
            // Check if the width has actually changed and it's not just a mobile browser adjusting the height
            if (window.innerWidth !== windowWidth) {
                setWindowWidth(window.innerWidth); // Update the width
            }
        };

        // Set the initial state based on current window size
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth]); // Dependency on windowWidth to update when it changes

    // console.log(document.getElementById('mobile_ptable_'+ result[0].name).height);

    // console.log(document.getElementById('country_' + result[0].name).offsetHeight);
    // if (result && document.getElementById('country_' + result[0].name)) {
    //     const data = sortedData.map((d) => {
    //         d.innerHeight = document.getElementById('country_' + d.name).offsetHeight
    //     });
    //     setData(data);
    //     setSortedData(data);
    // }

    const handleClickRow = (data, index) => {
        // popUpRef.current.scrollIntoView({ behavior: "smooth" });

        if (detailCard.country === data.country && showCard) {
            setShowCard(false);
            return;
        }
        setDetailCard(data);
        setShowCard(true);
        const topWidth = (windowWidth > 799) ? (numOfCountries - index - 1) * 70 : 0;
        const setter = (numOfCountries - index - 9);
        const bottomWidth = (windowWidth > 799 && setter > 0) ? (setter) * 70 + 350 : 0;
        setMarginTopRow(topWidth);
        setMarginBottomRow(bottomWidth);

        setMarginTopRow(0);
        setMarginBottomRow(0);

    }

    const cardClose = () => {
        setShowCard(false);
    }
    const handleExpand = (item, index) => {
        const country = item.country;
        if (isMobile) {
            // if d already in expanded list, remove it.
            // if it's not, add it.
            const expanded = expandedCountries.find((d) => d === country) ? expandedCountries.filter((d) => d !== country) : [...expandedCountries, country]
            setExpandedCountries(expanded);
        }
        handleClickRow(item, index);
    }

    const handleCheck = (e) => {
        const selected = e.target.checked ? [...selectedParameters, e.target.name] : selectedParameters.filter((d) => d !== e.target.name)
        selected.length >= 3 ? setDisabledParameters(true) : setDisabledParameters(false);
        setSelectedParameters(selected);

        if (selected.length === 0) {
            setSortedData(data);
        } else {
            const data = [...sortedData];

            const sorted = data.sort((a, b) => {
                const aTotal = a.scores.filter((e) =>
                    selected.find((f) => f === e.score)
                ).reduce((sum, e) => {
                    return sum + e.value
                }, 0).toFixed(1)
                const bTotal = b.scores.filter((e) =>
                    selected.find((f) => f === e.score)
                ).reduce((sum, e) => {
                    return sum + e.value
                }, 0).toFixed(1)
                return bTotal - aTotal;
            });

            const setTotal = sorted.map((d) => {
                return {
                    ...d,
                    total: d.scores.filter((e) =>
                        selected.find((f) => f === e.score)
                    ).reduce((sum, e) => {
                        return sum + e.value
                    }, 0)
                }
            });

            const setOfScore = [...new Set(setTotal.map(c => c.total))].sort((a, b) => b - a);
            const totalSorted = setTotal.map((d) => {
                return {
                    ...d,
                    rank: setOfScore.indexOf(d.total) + 1
                }
            });

            setSortedData(totalSorted.sort((a, b) => b.total - a.total || (a.country.toUpperCase()).localeCompare(b.country.toUpperCase())));
        }
    }

    const resetCheck = () => {
        Array.from(selectRef.current.children).map((d) => d.checked = false);
        setSelectedParameters([]);
        setDisabledParameters(false);
        setSortedData(data);
    }

    const handleParameterControl = () => {
        if (isMobile) {
            setCollapsedParameters(!collapsedParameters)
        }
    }

    const handleParameterHover = (e, icon, name, value, country) => {
        if (!e) {
            setHoveredParameter(e)
        } else {
            setHoveredParameter({
                x: 0,
                y: 0,
                icon: icon,
                name: name,
                value: value,
                country: country
            })
        }
    }

    const executeScroll = () => scrollRef.current.scrollIntoView()

    return (
        <div className={styles.cryptoAdoptionIndex}>
            {/*<h2 className={styles.embedH2}>Crypto Adoption Index</h2>*/}
            {/*<p>Click on the legend to add or remove parameters to create your own index based on the factors that matter*/}
            {/*    to you most. Hover over the chart to see individual scores for each parameter.</p>*/}

            <div className={styles.popupPosition}>
                {/*<div ref={popUpRef} className={styles.positionToScroll}></div>*/}
                {!isMobile && showCard ?
                    <div className={styles.safariMarginTopFix}
                         style={{'marginTop': `-${marginTopRow}px`, 'marginBottom': `${marginBottomRow}px`}}>
                        <WealthFlowDetail item={detailCard} onClose={cardClose}/>
                    </div>
                    : null}
            </div>

            <div className={styles.allEmbedContainer}>
                <div className={styles.leftColumn}>
                    <div className={styles.parameterSelection}>
                        <h3 onClick={handleParameterControl}>
                            Select up to 3 parameters to create your own index
                            {isMobile && (
                                <span
                                    className={styles.mobileCollapsedControl}
                                >
                                    {collapsedParameters && (
                                        iconPlus
                                    )}
                                    {!collapsedParameters && (
                                        iconMinus
                                    )}
                                </span>
                            )}
                        </h3>
                        <div className={styles.mobileCollapsed} data-collapsed={collapsedParameters}>
                            <ul className={styles.selectionList} ref={selectRef}>
                                {SCORE_COLUMNS.map((d) => {
                                    const IconComponent = d.icon;
                                    return (
                                        <li
                                            title={disabledParameters ? "Deselect a parameter to choose another" : ""}
                                            key={d.key}
                                        >
                                            <input
                                                type="checkbox"
                                                id={d.key}
                                                name={d.key}
                                                className={styles.parameterCheckbox}
                                                onClick={handleCheck}
                                                disabled={disabledParameters ? !selectedParameters.find((e) => e === d.key) : false}
                                            />
                                            <span className={styles.parameterControls}>

                                                <label htmlFor={d.key}>
                                                    <IconComponent alt={d.name} className={styles.parameterIcon}
                                                                   aria-hidden="true"/>
                                                    {d.name}</label>
                                                <span
                                                    className={styles.checkboxControl}
                                                    style={{
                                                        borderColor: d.color,
                                                        backgroundColor: d.color
                                                        // borderColor: disabledParameters ? selectedParameters.find((e) => e === d.key) ? d.color : "#C1C5C8" : d.color,
                                                        // backgroundColor: selectedParameters.find((e) => e === d.key) ? d.color : "transparent"
                                                    }}
                                                >
                                                    {selectedParameters.find((e) => e === d.key) && (
                                                        iconTick
                                                    )}
                                                </span>
                                            </span>
                                        </li>
                                    )
                                })}
                            </ul>
                            <div className={styles.selectionButtons}>
                                {isMobile && (
                                    <button className={styles.button} onClick={executeScroll}>
                                        See my index</button>
                                )}
                                {selectedParameters.length > 0 &&
                                    <button className={styles.buttonReset} onClick={resetCheck}>Reset</button>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.rightColumn} ref={scrollRef}>
                    <table className={styles.allTable}>
                        <thead>
                        <tr className={`text-uppercase ${styles.allTableHeader}`} >
                            <th className={styles.headerRank}>Rank</th>
                            <th className={styles.headerCountry}>Country</th>
                            <th className={styles.headerKey}></th>
                            <th className={styles.headerScore}>
                                <span>Total Score <span className={`d-block`}
                                >(Out of 60)</span></span></th>
                            {isMobile && (
                                <th className={styles.headerExpando}></th>
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {sortedData && sortedData.map((d, i) => (
                            <Fragment key={i}>
                                <tr key={d.country} onClick={() => handleExpand(d, i)} style={{ borderBottom: expandedCountries ? 'none': ''}}>
                                    {/*<td className={`${(d.innerHeight > 79) ? 'pt-4' : ''} ${styles.rankColumn}`} style={{paddingTop: (d.innerHeight > 79) ? d.innerHeight/4+'px':''}}>{getNumberWithOrdinalWithSuperscript(i + 1)}</td>*/}
                                    <td className={`align-text-top ${styles.rankColumn}`}
                                        // style={{paddingBottom: (d.innerHeight > 79) ? d.innerHeight / 4 + 'px' : ''}}
                                    >{getNumberWithOrdinalWithSuperscript(d.rank )}</td>
                                    <td id={'country_' + d.name}
                                        className={`${styles.countryNameColumn}`}>{d.country}</td>
                                    <td className={styles.barchartColumn}>
                                        <div aria-hidden="true" className={styles.barChartLabel}>{d.country}</div>
                                        <div className={styles.barchartBackground}></div>
                                        <div
                                            className={styles.barchartForeground}
                                            style={
                                                {
                                                    width: `${(d.total / (selectedParameters.length ? selectedParameters.length * 10 : 60)) * 100}%`,
                                                    gridTemplateColumns: d.scores.filter((e) => selectedParameters.length > 0 ? selectedParameters.find((f) => f === e.score) : e).map((s) => s.value + "fr ").join(" ")
                                                }
                                            }>
                                            {d.scores.filter((e) => selectedParameters.length > 0 ? selectedParameters.find((f) => f === e.score) : e).map((s, i) => {
                                                const IconComponent = s.icon;
                                                return (
                                                    <div
                                                        key={s.score}
                                                        className={styles.barchartElement}
                                                        data-label={s.score}
                                                        style={{
                                                            backgroundColor: s.color,
                                                            zIndex: (d.scores.length - i)
                                                        }}
                                                        data-tip={s.name + ": " + s.value}
                                                        onMouseOver={(e) => {
                                                            handleParameterHover(e, s.icon, s.name, s.value, d.country)
                                                        }}
                                                        onMouseOut={() => {
                                                            handleParameterHover(null)
                                                        }}
                                                    >
                                                        {!isMobile && hoveredParameter && d.country === hoveredParameter.country && hoveredParameter.name === s.name && (
                                                            <div className={styles.parameterHover}>
                                                                <div className={styles.parameterHoverContainer}>
                                                                        <span
                                                                            className={styles.parameterHoverContainerImage}>
                                                                            <IconComponent
                                                                                alt={hoveredParameter.score}
                                                                                className={styles.parameterIcon}
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    <span
                                                                        className={styles.parameterHoverContainerName}>
                                                                            {hoveredParameter.name}
                                                                        </span>
                                                                    <span
                                                                        className={styles.parameterHoverContainerValue}>
                                                                            {hoveredParameter.value.toFixed(1)}
                                                                        </span>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </td>

                                    <td className={styles.scoreColumn}>
                                        {isMobile && expandedCountries.find((e) => e === d.country) && (
                                            <span className={styles.scoreColumnFirstChild}>{d.total.toFixed(1)}</span>
                                        )}
                                        {!(isMobile && expandedCountries.find((e) => e === d.country)) && (
                                            <span className={styles.scoreColumnFirstChild}>
                                                {selectedParameters.length > 0 && (
                                                    <Fragment>
                                                        {
                                                            d.scores.filter((e) =>
                                                                selectedParameters.find((f) => f === e.score)
                                                            ).reduce((sum, e) => {
                                                                return sum + e.value
                                                            }, 0).toFixed(1)
                                                        }
                                                    </Fragment>
                                                )}
                                                {selectedParameters.length === 0 && (
                                                    <Fragment>
                                                        {d.total.toFixed(1)}
                                                    </Fragment>
                                                )}
                                            </span>
                                        )}
                                    </td>
                                    {isMobile && (
                                        <td className={`${styles.expandoControl}`}>
                                            <div>
                                                {expandedCountries.find((e) => e === d.country) && (
                                                    iconMinus
                                                )}
                                                {!expandedCountries.find((e) => e === d.country) && (
                                                    iconPlus
                                                )}
                                            </div>
                                        </td>

                                    )}

                                </tr>
                                {isMobile && expandedCountries.find((e) => e === d.country) && (

                                        <tr style={{borderBottom: 'none'}}>
                                            <td colSpan={5} >
                                                <CountryInfoDetail item={d} onClose={cardClose}/>
                                            </td>
                                        </tr>


                                )}

                                {/*<tr key={d.country} onClick={() => handleExpand(d, i)}>*/}
                                {isMobile && expandedCountries.find((e) => e === d.country) &&
                                    d.scores.filter((e) => selectedParameters.length > 0 ? selectedParameters.find((f) => f === e.score) : e).map((s, i) => {
                                    const IconComponent = s.icon;
                                    return (
                                        <tr key={s.score} className={styles.expandedCountryInfo}>
                                            <td className={styles.expandedCountryInfoIcon}>
                                                <IconComponent
                                                    alt={s.score}
                                                    className={styles.parameterIcon}
                                                    aria-hidden="true"
                                                />
                                            </td>
                                            <td className={styles.expandedCountryInfoName}>{s.name}</td>
                                            <td colSpan={2} className={styles.scoreColumn}><span key={s.score} className={styles.scoreColumnExpanded}>{s.value.toFixed(1)}</span></td>
                                            {/*<td></td>*/}
                                            {/*<td colSpan={4}></td>*/}

                                        </tr>
                                    )
                                })}
                                {/*{isMobile && expandedCountries.find((e) => e === d.country) && (*/}
                                {/*    <tr key={d.country} className={styles.expandedCountryInfo}>*/}
                                {/*    /!*<table className={styles.expandedCountryInfo}>*!/*/}
                                {/*    /!*    <tbody>*!/*/}
                                {/*        {d.scores.filter((e) => selectedParameters.length > 0 ? selectedParameters.find((f) => f === e.score) : e).map((s, i) => {*/}
                                {/*            const IconComponent = s.icon;*/}
                                {/*            return (*/}
                                {/*                <tr key={s.score}>*/}
                                {/*                    <td className={styles.expandedCountryInfoIcon}>*/}
                                {/*                        <IconComponent*/}
                                {/*                            alt={s.score}*/}
                                {/*                            className={styles.parameterIcon}*/}
                                {/*                            aria-hidden="true"*/}
                                {/*                        />*/}
                                {/*                    </td>*/}
                                {/*                    <td colSpan={2} className={styles.expandedCountryInfoName}>{s.name}</td>*/}
                                {/*                    <td  className={styles.scoreColumn}><span key={s.score}*/}
                                {/*                                                              className={styles.scoreColumnExpanded}>{s.value.toFixed(1)}</span>*/}
                                {/*                    </td>*/}
                                {/*                </tr>*/}
                                {/*            )*/}
                                {/*        })}*/}

                                {/*    /!*    </tbody>*!/*/}
                                {/*    /!*</table>*!/*/}
                                {/*    </tr>*/}
                                {/*)}*/}
                                <tr style={{ borderBottom: '1px solid #C1C5C8' }}><td colSpan={5} style={{ paddingTop: '1px'}}></td></tr>
                                    </Fragment>


                                ))}
                            </tbody>

                            </table>

                </div>
            </div>
        </div>
    );
}
