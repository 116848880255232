const cryptoWealthData = [
  {
    "worldWide": "Millionaires",
    "bitcoin": 85400,
    "totalCrypto": 172300,
    "bitcoinGrowth": 111,
    "totalCryptoGrowth": 95,
    "isTotal": false,
    "boldTotalCrypto": false
  },
  {
    "worldWide": "Centi-millionaires",
    "bitcoin": 156,
    "totalCrypto": 325,
    "bitcoinGrowth": 100,
    "totalCryptoGrowth": 79,
    "isTotal": false,
    "boldTotalCrypto": false
  },
  {
    "worldWide": "Billionaires",
    "bitcoin": 11,
    "totalCrypto": 28,
    "bitcoinGrowth": 83,
    "totalCryptoGrowth": 27,
    "isTotal": false,
    "boldTotalCrypto": false
  },
  {
    "worldWide": "Total crypto users (million)",
    "bitcoin": 275,
    "totalCrypto": 560,
    "bitcoinGrowth": 31,
    "totalCryptoGrowth": 32,
    "isTotal": true,
    "boldTotalCrypto": false
  },
  {
    "worldWide": "Total market value (USD trillion)",
    "bitcoin": 1.2,
    "totalCrypto": 2.3,
    "bitcoinGrowth": 103,
    "totalCryptoGrowth": 89,
    "isTotal": true,
    "boldTotalCrypto": false
  }
];
export {cryptoWealthData};