import React from 'react';
import styles from "./detail.module.scss";
import {formatNumberWithDot, imageLink} from '../../../hooks/utils';
import CountryFlag from "../countryFlag/country-flag";
import {getNumberWithOrdinalWithSuperscript} from "../../../utils/utils";
import SocialShare from "../social-share/social-share";

const countryLinks = [{'country': 'Singapore', 'text': '1st', 'digital': 1, 'suptext': 'st'},
    {'country': 'Hong Kong (SAR China)', 'text': '2nd', 'digital': 2, 'suptext': 'nd'},
    {'country': 'UAE', 'text': '3rd', 'digital': 3, 'suptext': 'rd'},
    {'country': 'USA', 'text': '4th', 'digital': 4, 'suptext': 'th'},
    {'country': 'UK', 'text': '5th', 'digital': 5, 'suptext': 'th'},
    {'country': 'Malta', 'text': '6th', 'digital': 6, 'suptext': 'th'},
];
// const countryLinks = [];

const CountryInfoDetail = ({item, onClose}) => {
    // const value = (item.inflowVal) ? item.inflowVal : item.outflowVal;
    const countryName = item.country;

    const value = item.total;
    // const signVal = value > 0 ? "+" + formatNumberWithDot(value) : "-" + formatNumberWithDot(Math.abs(value));
    const signVal = item.total;

    // const notes = item.notes ? item.notes : 'notes placement ';
    const urlText = `Apply for residence in ${countryName}`;
    const urlTextUK = `Apply for residence in the ${countryName}`;

    // const url = item.url ? (<p className="mt-auto mb-0">{urlText} <a href={item.url} target="_blank" rel="noreferrer" className="fw-bold text-underline">here</a></p>) : (<p className="mt-auto mb-0">&nbsp;</p>);
    const url = item.url ? (<p className="mt-auto mb-0">{urlText} <a href={item.url} target="_blank" rel="noreferrer"
                                                                     className="fw-bold text-underline">here</a>.
    </p>) : (null);

    // const circleMark = item.category === "inflow" ? (<span className="circle circle-inflow"></span>) : (<span className="circle circle-outflow"></span>);

    const targetLinksItem = countryLinks.filter((i) => i.country === item.country)[0];
    const splited = (targetLinksItem) ? (item.notes).split(targetLinksItem.text) : null;
    const newNotesCountry = (targetLinksItem) ?
        (<p className="mt-auto">{splited[0]} <a href={targetLinksItem.link} target="_blank" rel="noreferrer" className="fw-bold text-underline">{targetLinksItem.text}</a> {splited[1]}</p>)
        : (<p>{item.notes}</p>);
    const newNotes = (targetLinksItem) ?
        (<p className="mt-auto">{splited[0]} <span>{targetLinksItem.digital}<sup>{targetLinksItem.suptext}</sup></span> {splited[1]}</p>)
        : (<p>{item.notes}</p>);

    return (
        <div className={`m-1 ${styles.hiddenDesktop}`}>
            {/*<div*/}
            {/*    className={`container shadow ${styles.popupWealthFlowCard} ${styles.card} p-0 bg-light`*/}
            {/*    // style={{top: '-50px'}}*/}
            {/*}*/}
            {/*>*/}
            <div className="col position-static" style={{backgroundColor: 'inherit'}}>
                <div className="row">

                    <div className={styles.countryImageWrapper}>
                        <img src={imageLink(item.name)} alt={countryName}/>
                    </div>
                </div>
                <div className={`row d-flex flex-column justify-content-between p-0 pt-4`}>
                    <div className='row '>
                        <div className={`col-md-8 col-sm-12`}>
                            {/*<div className={`col-8 ${styles.col8p5}`}>*/}
                            <span className={`text-uppercase ${styles.headerScore} text-right`}>The Henley Crypto Adoption Index 2024 <span
                                className={styles.outOf60}><br/></span></span>
                            <h3
                                className={styles.headerH2}>{item.country}</h3>
                        </div>

                        <div className={`d-none d-lg-block col-md-4 d-inline-block text-right ${styles.hiddenMobile}`}>
                            {/*<div className={`col-4 d-flex flex-column justify-content-between text-right ${styles.col3p5}`}>*/}
                            {/*<div className="col-4 d-flex flex-column justify-content-between">*/}
                            {/*<h3 className={` text-right text-nowrap align-bottom ${styles.hwni}`}>{signVal} HNWIs</h3>*/}
                            {/*</div>*/}
                            <span
                                className={`text-uppercase ${styles.headerScore} ${styles.scoreField}`}>Total Score<span
                                className={styles.outOf60}>(Out of 60)</span></span>
                            <h3 className={styles.headerH2}>{item.total.toFixed(1)}</h3>
                        </div>
                    </div>
                    {newNotes}

                    {(item.name === 'uk') ?
                        (<p className="mt-auto mb-0">{urlTextUK} <a href={item.url} target="_blank" rel="noreferrer" className="fw-bold text-underline">here</a>.</p>)
                        : url}

                    <div className={`my-3`}>
                        <SocialShare shareUrl={item.url}/>
                    </div>

                </div>


            </div>

        </div>

    )
}

export default CountryInfoDetail;
