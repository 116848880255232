import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from "./App";
import qs from "qs";
import CryptoInvestmentMigration from "./components/crypto-investment-migration/CryptoInvestmentMigration";
import CryptoWealth from "./components/crypto-wealth/crypto-wealth";
import CryptoAdoptionIndex from "./components/crypto-adoption-index/CryptoAdoptionIndex";

function getEl(embedSelector) {
    if (typeof embedSelector === "string") {
        const el = document.querySelector(embedSelector);
        // if (!el) throw new Error(`No div matching selector "${embedSelector}"`);
        if (!el) return;
        return el;
    } else {
        return embedSelector;
    }
}

function getEls(embedSelector) {
    if (typeof embedSelector === "string") {
        const els = document.querySelectorAll(embedSelector);
        // if (!els) throw new Error(`No divs matching selector "${embedSelector}"`);
        if (!els) return;
        return els;
    } else {
        return embedSelector;
    }
}

function getUrlParams() {
    const params = qs.parse(window.location.search.slice(1));

    return {
        type: params.embed,
        city: params.city,
    };
}

window.addEventListener("DOMContentLoaded", () => {
        if (getEl("[data-crypto-wealth-2024]")) {
            const container = getEl("[data-crypto-wealth-2024]");
            const root = createRoot(container); // createRoot(container!) if you use TypeScript
            // ReactDOM.render(<App {...params} />, getEl("[data-app-embed]"));
            root.render(<CryptoWealth />);
        }

        if (getEl("[data-crypto-adoption-index-2024]")) {
            const container = getEl("[data-crypto-adoption-index-2024]");
            const root = createRoot(container); // createRoot(container!) if you use TypeScript
            // ReactDOM.render(<App {...params} />, getEl("[data-app-embed]"));
            root.render(<CryptoAdoptionIndex />);
        }

        const params = getUrlParams();
        if (params) {
            [...getEls("[data-crypto-investment-migration-2024]")].forEach((container) => {
                const root = createRoot(container); // createRoot(container!) if you use TypeScript
                root.render(<CryptoInvestmentMigration {...params} iso={container.getAttribute("data-crypto-investment-migration-2024")}/>);
            })
        }
});
