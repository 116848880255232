const  IconLink= () => {

    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M13.0624015,2.93759849 C12.46627,2.33748015 11.6553456,2 10.8094645,2 C9.96358338,2 9.15265894,2.33748015 8.55652742,2.93759849 L7.34270011,4.15142579 C7.16713036,4.34519364 7.17445351,4.64262656 7.35934634,4.82751939 C7.54423917,5.01241222 7.84167209,5.01973537 8.03543994,4.84416562 L9.24926724,3.63033831 C10.1124478,2.77231205 11.5064812,2.77231205 12.3696617,3.63033831 C12.7848352,4.04339271 13.0182562,4.60488807 13.0182562,5.19053553 C13.0182562,5.776183 12.7848352,6.33767836 12.3696617,6.75073276 L10.6347469,8.48564754 C9.77156638,9.34367381 8.37753297,9.34367381 7.51435246,8.48564754 C7.39283363,8.35153309 7.20733078,8.29524183 7.03177307,8.33920773 C6.85621535,8.38317362 6.71913871,8.52025027 6.67517281,8.69580798 C6.63120691,8.8713657 6.68749818,9.05686855 6.82161263,9.17838737 C8.0662627,10.4217148 10.0828367,10.4217148 11.3274867,9.17838737 L13.0624015,7.44347258 C13.6625199,6.84734106 14,6.03641662 14,5.19053553 C14,4.34465445 13.6625199,3.53373001 13.0624015,2.93759849 Z" fill="#405363" fillRule="nonzero"></path>
                <path d="M8.03543994,11.0849545 L6.82161263,12.2987818 C5.95477903,13.1292396 4.58313812,13.1145431 3.73429752,12.2657025 C2.88545693,11.4168619 2.87076035,10.045221 3.70121819,9.17838737 L5.43613297,7.44347258 C6.29931349,6.58544632 7.6933469,6.58544632 8.55652742,7.44347258 C8.75029526,7.61904233 9.04772819,7.61171918 9.23262102,7.42682635 C9.41751384,7.24193352 9.424837,6.9445006 9.24926724,6.75073276 C8.00461718,5.50740534 5.98804322,5.50740534 4.74339315,6.75073276 L3.00847836,8.48564754 C2.15922434,9.28123897 1.81096946,10.4763788 2.0999201,11.6036346 C2.38887074,12.7308904 3.26910956,13.6111293 4.39636536,13.9000799 C5.52362116,14.1890305 6.71876103,13.8407757 7.51435246,12.9915216 L8.72817976,11.7776943 C8.90374952,11.5839265 8.89642636,11.2864936 8.71153353,11.1016007 C8.52664071,10.9167079 8.22920778,10.9093848 8.03543994,11.0849545 Z" fill="#405363" fillRule="nonzero"></path>
            </g>
    </svg>
    )

}

export default IconLink;