const cryptoInvestmentMigrationData = [
  {
    "rank": 1,
    "country": "Singapore",
    "name": "singapore",
    "iso": "SG",
    "publicAdoption": 4.6,
    "infrastructureAdoption": 6.8,
    "innovationAndTechnology": 9.5,
    "regulatoryEnvironment": 8.3,
    "economicFactors": 9.1,
    "taxFriendliness": 7.4,
    "total": 45.7,
    "notes": "Taking 1st place in the Henley Crypto Adoption Index 2024, Singapore leads in the deployment of a financial, business, and regulatory environment that fosters crypto adoption due to its supportive banking system, significant investment, comprehensive regulations such as the Payment Services Act, regulatory sandboxes, and alignment with global standards.",
    "url": "https://www.henleyglobal.com/residence-investment/singapore"
  },
  {
    "rank": 2,
    "country": "Hong Kong (SAR China)",
    "name": "hong-kong",
    "iso": "HK",
    "publicAdoption": 5.2,
    "infrastructureAdoption": 6.4,
    "innovationAndTechnology": 5.8,
    "regulatoryEnvironment": 5.9,
    "economicFactors": 9.8,
    "taxFriendliness": 9,
    "total": 42.1,
    "notes": "Hong Kong (SAR China) ranks 2nd in the Henley Crypto Adoption Index 2024 due to having cultivated an environment that promotes the growth of the crypto industry through significant public interest, high business acceptance in the financial and tech sectors, and advanced digital infrastructure, making it easy for individuals to use and invest in cryptocurrencies.",
    "url": "https://www.henleyglobal.com/residence-investment/hong-kong"
  },
  {
    "rank": 3,
    "country": "UAE",
    "name": "uae",
    "iso": "AE",
    "publicAdoption": 6.4,
    "infrastructureAdoption": 4.1,
    "innovationAndTechnology": 6.8,
    "regulatoryEnvironment": 5.7,
    "economicFactors": 8.8,
    "taxFriendliness": 10,
    "total": 41.8,
    "notes": "The UAE stands out as a leading jurisdiction for crypto investors, ranking 3rd in the Henley Crypto Adoption Index 2024. Public interest is high, with a substantial portion of the population owning cryptocurrencies. This enthusiasm is matched by strong government support and a thriving start-up scene. This low-tax jurisdiction offers an attractive environment for crypto businesses, further bolstered by a highly digitalized and wealthy population.",
    "url": "https://www.henleyglobal.com/residence-investment/united-arab-emirates"
  },
  {
    "rank": 4,
    "country": "USA",
    "name": "usa",
    "iso": "US",
    "publicAdoption": 7.1,
    "infrastructureAdoption": 6.7,
    "innovationAndTechnology": 6.5,
    "regulatoryEnvironment": 6.6,
    "economicFactors": 8.4,
    "taxFriendliness": 6.4,
    "total": 41.7,
    "notes": "The USA ranks 4th in the Henley Crypto Adoption Index 2024, with significant public interest — 15% of the population owns cryptocurrencies. This is supported by strong infrastructure, with a high density of crypto ATMs, crypto-friendly banks, and an increasing number of businesses accepting cryptocurrency. Innovation thrives, with government initiatives, despite a regulatory environment marked by structured regulations and robust enforcement.",
    "url": "https://www.henleyglobal.com/residence-investment/united-states"
  },
  {
    "rank": 5,
    "country": "UK",
    "name": "uk",
    "iso": "GB",
    "publicAdoption": 3.9,
    "infrastructureAdoption": 5.4,
    "innovationAndTechnology": 6,
    "regulatoryEnvironment": 6.5,
    "economicFactors": 7.9,
    "taxFriendliness": 6.4,
    "total": 36.1,
    "notes": "The UK ranks 5th in the Henley Crypto Adoption Index 2024, with moderate public interest and growing bank integration, particularly among challenger banks. The country is highly digitalized, and virtually all citizens have banking access. The government fosters innovation through crypto initiatives and fintech hubs, while regulatory sandboxes and cautious regulation support growth. The taxation framework is clear and established, ensuring a well-defined structure for crypto-related activities.",
    "url": "https://www.henleyglobal.com/residence-investment/united-kingdom"
  },
  {
    "rank": 6,
    "country": "Malta",
    "name": "malta",
    "iso": "MT",
    "publicAdoption": 2.8,
    "infrastructureAdoption": 6.3,
    "innovationAndTechnology": 4.3,
    "regulatoryEnvironment": 7,
    "economicFactors": 7.8,
    "taxFriendliness": 7.8,
    "total": 36,
    "notes": "Malta ranks 6th in the Henley Crypto Adoption Index 2024, benefiting from a strong regulatory framework and a high level of government support. While public adoption is moderate, the island nation has become a global hub for blockchain and crypto businesses. Favorable regulations attract numerous exchanges and start-ups, supported by Malta’s Digital Innovation Authority, which oversees a comprehensive regulatory environment balancing innovation with investor protection. Clear taxation policies further support Malta’s thriving crypto ecosystem.",
    "url": "https://www.henleyglobal.com/residence-investment/malta"
  },
  {
    "rank": 7,
    "country": "Australia",
    "name": "australia",
    "iso": "AU",
    "publicAdoption": 3.6,
    "infrastructureAdoption": 5.1,
    "innovationAndTechnology": 6.7,
    "regulatoryEnvironment": 7.5,
    "economicFactors": 7.6,
    "taxFriendliness": 4.1,
    "total": 34.6,
    "notes": "Australia is laying the groundwork to become a significant crypto hub, with its supportive legal framework and government initiatives. While public interest in cryptocurrencies is moderate, the crypto infrastructure is steadily advancing, marked by an increasing number of crypto ATMs and exchanges, and businesses embracing crypto.",
    "url": "https://www.henleyglobal.com/residence-investment/australia"
  },
  {
    "rank": 8,
    "country": "Malaysia",
    "name": "malaysia",
    "iso": "MY",
    "publicAdoption": 4.8,
    "infrastructureAdoption": 2.1,
    "innovationAndTechnology": 5.4,
    "regulatoryEnvironment": 6.1,
    "economicFactors": 7.6,
    "taxFriendliness": 8.1,
    "total": 34.1,
    "notes": "Malaysia's challenging economic landscape has fueled public interest in cryptocurrencies, with approximately 3% of the population owning digital assets. The government's supportive stance in recognizing cryptocurrencies as securities attracts businesses and start-ups. Although the infrastructure is still in its early stages, with few crypto ATMs and businesses accepting crypto payments, ongoing government initiatives are expected to bolster development in the near future.",
    "url": "https://www.henleyglobal.com/residence-investment/malaysia"
  },
  {
    "rank": 9,
    "country": "Canada",
    "name": "canada",
    "iso": "CA",
    "publicAdoption": 4,
    "infrastructureAdoption": 5,
    "innovationAndTechnology": 3,
    "regulatoryEnvironment": 7.5,
    "economicFactors": 8.2,
    "taxFriendliness": 5.2,
    "total": 32.9,
    "notes": "Canada has a well-developed regulatory and economic environment for cryptocurrency adoption, characterized by comprehensive regulations and a balanced approach. Public interest in cryptocurrencies is high, although ownership remains moderate. Major urban areas such as Toronto and Vancouver feature developed infrastructures with numerous crypto ATMs, exchanges, and crypto-friendly businesses.",
    "url": "https://www.henleyglobal.com/residence-investment/canada"
  },
  {
    "rank": 10,
    "country": "Thailand",
    "name": "thailand",
    "iso": "TH",
    "publicAdoption": 4.9,
    "infrastructureAdoption": 2.6,
    "innovationAndTechnology": 1.8,
    "regulatoryEnvironment": 7.6,
    "economicFactors": 8.6,
    "taxFriendliness": 6.4,
    "total": 31.9,
    "notes": "Thailand is a crypto-friendly nation, with high public interest in cryptocurrencies and nearly 10% of the population owning crypto assets. The government supports crypto adoption, recognizing digital assets and implementing specific regulations for initial coin offerings and exchanges. While the regulatory framework is robust, the infrastructure for cryptocurrency use is still developing and requires further investment and innovation.",
    "url": "https://www.henleyglobal.com/residence-investment/thailand"
  },
  {
    "rank": 11,
    "country": "Switzerland",
    "name": "switzerland",
    "iso": "CH",
    "publicAdoption": 3.1,
    "infrastructureAdoption": 3.6,
    "innovationAndTechnology": 3.8,
    "regulatoryEnvironment": 5.5,
    "economicFactors": 8.4,
    "taxFriendliness": 6.7,
    "total": 31.1,
    "notes": "Switzerland does not have specific laws for cryptocurrencies or mining; instead, crypto companies are regulated under existing financial intermediary categories. Although only 2% of the population own digital assets, the Swiss government is supportive of the crypto industry, exemplified by the \"Crypto Valley\" of Zug. The country's developed infrastructure and proactive innovation policies make it a prominent hub for blockchain and crypto start-ups.",
    "url": "https://www.henleyglobal.com/residence-investment/switzerland"
  },
  {
    "rank": 12,
    "country": "Cyprus",
    "name": "cyprus",
    "iso": "CY",
    "publicAdoption": 4.4,
    "infrastructureAdoption": 2.6,
    "innovationAndTechnology": 2.3,
    "regulatoryEnvironment": 6.3,
    "economicFactors": 7.5,
    "taxFriendliness": 7.8,
    "total": 30.9,
    "notes": "Cyprus has high public interest in cryptocurrencies but ownership is low. Its crypto infrastructure is still developing, with few businesses accepting crypto payments, and limited banking services available. The country has established a legal framework for cryptocurrencies, recognizing them as assets and subjecting them to existing financial laws, with enforcement actions focusing on severe violations.",
    "url": "https://www.henleyglobal.com/residence-investment/cyprus"
  },
  {
    "rank": 13,
    "country": "Austria",
    "name": "austria",
    "iso": "AT",
    "publicAdoption": 3.8,
    "infrastructureAdoption": 2.4,
    "innovationAndTechnology": 3.3,
    "regulatoryEnvironment": 5.1,
    "economicFactors": 7.9,
    "taxFriendliness": 6.6,
    "total": 29.1,
    "notes": "Austria’s crypto adoption is characterized by growing public interest and a supportive regulatory environment. The country’s infrastructure is developing steadily, with an increasing number of crypto ATMs and businesses accepting digital currencies. Austria’s cautious yet progressive regulatory approach ensures a stable environment for the continued growth of its crypto industry.",
    "url": "https://www.henleyglobal.com/residence-investment/austria"
  },
  {
    "rank": 14,
    "country": "New Zealand",
    "name": "new-zealand",
    "iso": "NZ",
    "publicAdoption": 3.6,
    "infrastructureAdoption": 3.4,
    "innovationAndTechnology": 0.7,
    "regulatoryEnvironment": 6.3,
    "economicFactors": 8.2,
    "taxFriendliness": 6.3,
    "total": 28.5,
    "notes": "New Zealand is steadily growing as a hub for cryptocurrency adoption, supported by a favorable regulatory environment and a tech-savvy population. While public interest in cryptocurrencies is rising, ownership remains moderate. However, ongoing crypto service integration by banks signals increased institutional support for the sector, fostering a growing network of crypto-friendly businesses.",
    "url": "https://www.henleyglobal.com/residence-investment/new-zealand"
  },
  {
    "rank": 14,
    "country": "Portugal",
    "name": "portugal",
    "iso": "PT",
    "publicAdoption": 3.3,
    "infrastructureAdoption": 2.2,
    "innovationAndTechnology": 3.4,
    "regulatoryEnvironment": 5.1,
    "economicFactors": 7.8,
    "taxFriendliness": 6.7,
    "total": 28.5,
    "notes": "Portugal is renowned for its crypto-friendly tax policies, particularly the absence of capital gains tax on individual crypto investments, which has attracted a significant number of crypto enthusiasts and businesses. The government's approach to cryptocurrency regulation is relatively hands-off, focusing on monitoring and compliance rather than strict enforcement, which has created a welcoming environment for innovation, contributing to growth in the crypto sector. ",
    "url": "https://www.henleyglobal.com/residence-investment/portugal"
  },
  {
    "rank": 15,
    "country": "Italy",
    "name": "italy",
    "iso": "IT",
    "publicAdoption": 4.4,
    "infrastructureAdoption": 2.8,
    "innovationAndTechnology": 3.7,
    "regulatoryEnvironment": 5.1,
    "economicFactors": 7.5,
    "taxFriendliness": 4.9,
    "total": 28.4,
    "notes": "Italy may lack a fully defined legal framework specifically for cryptocurrencies but the country is gradually integrating crypto into its financial system. Public interest is increasing, but ownership remains moderate. The infrastructure, including crypto ATMs and businesses accepting crypto payments, is expanding, especially in major cities. However, the regulatory environment is still evolving, and the government's approach remains cautious, as it balances innovation with consumer protection.",
    "url": "https://www.henleyglobal.com/residence-investment/italy"
  },
  {
    "rank": 15,
    "country": "Mauritius",
    "name": "mauritius",
    "iso": "MU",
    "publicAdoption": 4.6,
    "infrastructureAdoption": 0.4,
    "innovationAndTechnology": 1.6,
    "regulatoryEnvironment": 5.8,
    "economicFactors": 6.9,
    "taxFriendliness": 9.1,
    "total": 28.4,
    "notes": "Mauritius is positioning itself as a favorable destination for cryptocurrency and blockchain activities, with a particular emphasis on its tax advantages. While the country's fintech innovation and infrastructure are still developing, the government’s commitment to supporting digital finance initiatives is clear. The Financial Services Commission is actively developing regulatory guidelines that balance innovation with investor protection, and the introduction of regulatory sandboxes provides a controlled environment for start-ups to test new ideas. This strategic approach, combined with Mauritius's attractive tax policies, is contributing to its emerging role in Africa's growing crypto landscape.",
    "url": "https://www.henleyglobal.com/residence-investment/mauritius"
  },
  {
    "rank": 16,
    "country": "Panama",
    "name": "panama",
    "iso": "PA",
    "publicAdoption": 4.3,
    "infrastructureAdoption": 3.7,
    "innovationAndTechnology": 2.2,
    "regulatoryEnvironment": 3.8,
    "economicFactors": 5.2,
    "taxFriendliness": 8.4,
    "total": 27.6,
    "notes": "Panama’s government has been relatively open to cryptocurrency adoption, with a focus on creating a legal framework that encourages innovation while ensuring compliance with international standards. The country’s position as an international financial hub, coupled with its tax-friendly policies, makes it an attractive destination for crypto businesses and start-ups. However, infrastructure, such as crypto ATMs and exchanges, is still developing and further expansion is required to support widespread adoption.",
    "url": "https://www.henleyglobal.com/residence-investment/panama"
  },
  {
    "rank": 17,
    "country": "Spain",
    "name": "spain",
    "iso": "ES",
    "publicAdoption": 3.9,
    "infrastructureAdoption": 2.5,
    "innovationAndTechnology": 2.7,
    "regulatoryEnvironment": 5.3,
    "economicFactors": 8.4,
    "taxFriendliness": 4.7,
    "total": 27.5,
    "notes": "Spain has a relatively favorable regulatory environment for cryptocurrencies, with developing guidelines on taxation and regulation. This has resulted in a growing number of businesses accepting crypto payments, particularly in tourism and retail in Catalonia and the Canary Islands. Public interest is high but ownership remains moderate, with many Spaniards cautious about entering the crypto space. The government has been active in exploring blockchain technology for public services, but overall adoption is still limited.",
    "url": "https://www.henleyglobal.com/residence-investment/spain"
  },
  {
    "rank": 18,
    "country": "Luxembourg",
    "name": "luxembourg",
    "iso": "LU",
    "publicAdoption": 3.7,
    "infrastructureAdoption": 3.7,
    "innovationAndTechnology": 1.8,
    "regulatoryEnvironment": 2.6,
    "economicFactors": 8.2,
    "taxFriendliness": 7,
    "total": 27,
    "notes": "Luxembourg’s government has been supportive of blockchain technology, with initiatives aimed at integrating blockchain into various sectors. This has indirectly fostered interest in cryptocurrencies. Furthermore, the country is home to several financial institutions that have begun to integrate crypto services, contributing to the development of a crypto-friendly business ecosystem. While the number of businesses accepting cryptocurrency as payment is still limited, the existing infrastructure, coupled with the country’s favorable regulatory environment, suggests that Luxembourg is well-positioned for further growth in crypto adoption.",
    "url": "https://www.henleyglobal.com/residence-investment/luxembourg"
  },
  {
    "rank": 19,
    "country": "Hungary",
    "name": "hungary",
    "iso": "HU",
    "publicAdoption": 4,
    "infrastructureAdoption": 2,
    "innovationAndTechnology": 2.1,
    "regulatoryEnvironment": 2.7,
    "economicFactors": 7.6,
    "taxFriendliness": 7.7,
    "total": 26.1,
    "notes": "Crypto adoption in Hungary is gradually gaining traction, though it remains relatively modest compared to other European countries. Public interest in cryptocurrencies is growing, driven by increasing awareness and the rise of digital finance. However, ownership of cryptocurrencies is still low among the population. The regulatory environment is somewhat cautious, with the government showing interest in blockchain technology but maintaining strict oversight to prevent money laundering and other illegal activities. Infrastructure supporting crypto adoption, such as crypto exchanges and ATMs, is still developing.",
    "url": "https://www.henleyglobal.com/residence-investment/hungary"
  },
  {
    "rank": 19,
    "country": "Monaco",
    "name": "monaco",
    "iso": "MC",
    "publicAdoption": 0.1,
    "infrastructureAdoption": 3.3,
    "innovationAndTechnology": 0.5,
    "regulatoryEnvironment": 4.5,
    "economicFactors": 8.5,
    "taxFriendliness": 9.2,
    "total": 26.1,
    "notes": "Crypto adoption in Monaco is still emerging, with government initiatives signaling a growing interest in blockchain technology. Although public adoption is limited due to the principality's small size and wealthy population, the government is creating a supportive regulatory environment. Infrastructure development is underway, with a few businesses accepting crypto, and financial institutions beginning to explore digital assets. While cautious in its approach, Monaco is laying the groundwork for future growth in the crypto sector.",
    "url": "https://www.henleyglobal.com/residence-investment/monaco"
  },
  {
    "rank": 20,
    "country": "Antigua and Barbuda",
    "name": "antigua",
    "iso": "AG",
    "publicAdoption": 1,
    "infrastructureAdoption": 0.9,
    "innovationAndTechnology": 0.5,
    "regulatoryEnvironment": 6.5,
    "economicFactors": 5.9,
    "taxFriendliness": 8.4,
    "total": 23.2,
    "notes": "Antigua and Barbuda is establishing itself as a crypto-friendly jurisdiction, particularly for offshore businesses and investors. The government is keen on integrating blockchain technology to boost the economy and developing legal frameworks to support crypto businesses, although public adoption is in its early stages. The country aims to position itself as a hub for digital innovation, although the infrastructure, such as crypto ATMs and businesses accepting cryptocurrency, is still growing. As the ecosystem matures, Antigua and Barbuda could become a significant player in the global crypto market.",
    "url": "https://www.henleyglobal.com/citizenship-investment/antigua-barbuda/"
  }
];

export {cryptoInvestmentMigrationData};