import {useMemo} from "react";
import {cryptoInvestmentMigrationData} from "../../data/data-crypto-investment-migration";
import styles from "./CryptoInvestmentMigration.module.scss";
import {useIsMobile} from "../../hooks/mobile";
import {getNumberWithOrdinalWithSuperscript} from "../../utils/utils";

import IconAdoption from '../common/icon/icon-adoption';
import IconEconomic from '../common/icon/icon-economic';
import IconInfrastructure from '../common/icon/icon-infrastructure';
import IconInnovations from '../common/icon/icon-innovations';
import IconRegulatory from '../common/icon/icon-regulatory';
import IconTax from '../common/icon/icon-tax';

const SCORES = [
    {
        key: "publicAdoption",
        name: "Public adoption",
        icon: IconAdoption
    },
    {
        key: "infrastructureAdoption",
        name: "Infrastructure adoption",
        icon: IconInfrastructure
    },
    {
        key: "innovationAndTechnology",
        name: "Innovation and technology",
        icon: IconInnovations
    },
    {
        key: "regulatoryEnvironment",
        name: "Regulatory environment",
        icon: IconRegulatory
    },
    {
        key: "economicFactors",
        name: "Economic factors",
        icon: IconEconomic
    },
    {
        key: "taxFriendliness",
        name: "Tax-friendliness",
        icon: IconTax
    }
]

export default function CryptoInvestmentMigration(props) {
    const {iso} = props;
    const isMobile = useIsMobile();
    const ICON_SIZE = isMobile ? 30 : 36;
    const data = useMemo(() => {
        // find dataset based on the slug
        const result = cryptoInvestmentMigrationData.find((d) => d.iso.toLowerCase() === iso.toLowerCase())
        if (!result) {
            return null;
        }
        return result;
    }, [iso])

    return (
        <div className={styles.cryptoInvestmentMigration}>

            {data && (
                <table className={styles.cryptoInvestmentMigrationTable}>
                    <thead>
                    <tr className={`${styles.cryptoInvestmentMigrationTableHead} ${styles.noHenleyBorderBottom}`}>
                        <th colSpan={4} className={styles.henleyHeaderTitle}>
                            <span className={`text-uppercase ${styles.cryptoInvestmentMigrationTableHeaderScore}`}>
                                The Henley Crypto Adoption Index 2024
                            </span>
                        </th>

                    </tr>
                    <tr className={styles.cryptoInvestmentMigrationTableHead}>
                        <th colSpan={isMobile ? 2 : 4} className={styles.henleyHeaderTitle}>
                            <span className={`text-uppercase align-text-top ${styles.cryptoInvestmentMigrationTableHeaderScore} ${styles.henleyHeaderDesktop}`}>The Henley Crypto Adoption Index 2024
                                <span className={`d-block`}><br style={{lineHeight: 2}}/></span>
                            </span>
                        <h2
                                className={styles.cryptoInvestmentMigrationTableHeader}>{getNumberWithOrdinalWithSuperscript(data.rank)}{" " + data.country}</h2>
                        </th>
                        {/*<th className={styles.percentage}>*/}
                        {/*/!*<span className={styles.cryptoInvestmentMigrationTableHeaderScore}>Percentage</span>*!/*/}
                        {/*</th>*/}
                        <th colSpan={isMobile ? 3 : 1} className={styles.totalScore}>
                            <span className={`text-uppercase ${styles.cryptoInvestmentMigrationTableHeaderScore}`}>Total Score
                                {/*<span className={styles.outOf60}>*/}
                                <span className={`d-block`}>
                                    (Out of 60)</span></span>
                            <h2 className={styles.cryptoInvestmentMigrationTableHeaderH2}>{data.total.toFixed(1)}</h2>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {data && SCORES.map((score) => {
                        const IconComponent = score.icon;
                        return (
                            <tr key={score.key}>
                                <td aria-hidden="true" className={styles.iconColumn}>
                                    <IconComponent alt={data[score.key]} className={styles.parameterIcon}
                                                   style={{height: "auto", width: ICON_SIZE}} aria-hidden="true"/>
                                </td>
                                <td className={styles.nameColumn}>{score.name}</td>
                                <td colSpan={2} className={styles.barchartColumn}>
                                    <div className={styles.barchartBackground}></div>
                                    <div className={styles.barchartForeground}
                                         style={{width: `${data[score.key] * 10}%`}}></div>
                                    <div className={styles.barChartLabel}>{score.name}</div>
                                </td>
                                <td className={styles.scoreColumn}>{data[score.key].toFixed(1)}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            )}
        </div>
    );
}
