import React, {useState} from 'react';
import styles from "./social-share.module.scss";
import IconLinkedIn from '../icon/icon-linkedin';
import IconSociaMedialX from '../icon/icon-social-media-x';
import IconLink from '../icon/icon-link';
import { getLinkedinUrl, getTwitterUrl} from '@phntms/react-share';

const SocialShare = ({shareUrl}) =>{
//     const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent('au passport')}&summary=${encodeURIComponent('au summary')}&source=${encodeURIComponent('au source')}`;
//  // Function to open the LinkedIn share dialog

//     const shareOnLinkedIn = () => {
//         window.open(linkedInUrl, '_blank');
//     };

  const [copied, setCopied] = useState(false);

  const copyToClipboard = (shareUrl) => {
    navigator.clipboard.writeText(shareUrl)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset state after 2 seconds
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
  };

  const copiedTooltip = () =>{
    return(
        <div className={styles.tooltip}>
          Link copied
        </div>
    )
  }


  return(
      <div className="d-flex align-items-center flex-row position-relative">
            <span className={styles.share_label}>
                {/* <span className={styles.label_icon}>
                    <img src={shareIconImage} alt="Share" className="img-fluid" />
                </span> */}
              <span className={styles.label_text}>
                    SHARE
                </span>
            </span>

        <span
            className={styles.share_icon}
            onClick={() => window.open(getLinkedinUrl({url: shareUrl}), '_blank')}
        >
                <IconLinkedIn />
            </span>
        <span
            className={styles.share_icon}
            onClick={() => window.open(getTwitterUrl({url: shareUrl}), '_blank')}
        >
                <IconSociaMedialX />
            </span>
        <span className={styles.share_icon} onClick={() => copyToClipboard(shareUrl)}>
                <IconLink />
          {copied ? copiedTooltip() : ''}
          {/* {copiedTooltip()} */}
            </span>
      </div>
  )
}

export default SocialShare;