const  IconSociaMedialX= () => {

    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M9.14163,7.08118105 L13.6089,2 L12.5503,2 L8.67137,6.4119181 L5.57328,2 L2,2 L6.68492,8.67160156 L2,14 L3.05866,14 L7.15491,9.3408645 L10.42672,14 L14,14 L9.14137,7.08118105 L9.14163,7.08118105 Z M8.19988607,8.68027666 L7.73097318,8.04611222 L4,3 L5.60628449,3 L8.61722328,7.07237362 L9.08613618,7.70653806 L13,13 L11.3937155,13 L8.19988607,8.68051951 L8.19988607,8.68027666 Z" fill="#405363" fillRule="nonzero"></path>
            </g>
        </svg>
    )

}

export default IconSociaMedialX;